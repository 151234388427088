import React from "react";

interface FlagProps {
  country: string;
}

const Flag: React.FC<FlagProps> = ({ country }) => {
  // Dynamically import the SVG based on the country name
  let flagSrc;
  try {
    // Dynamically import the SVG based on the country name
    flagSrc = require(`./assets/flags/${country.toUpperCase()}.svg`);
  } catch (error) {
    // If the country flag doesn't exist, fall back to ROW.svg
    flagSrc = require("./assets/flags/ROW.svg").default;
  }

  return (
    <div className="flag-container">
      <img src={flagSrc} alt={`${country} flag`} className="flag-img" />
      <div className="gradient-overlay"></div>
    </div>
  );
};

export default Flag;
