import React from "react";
import privacyPolicyData from "./assets/privacyPolicy.json";
import "./Privacy.css";

const Privacy: React.FC = () => {
  const processContent = (content: string) => {
    return content
      .replace(/- \*\*(.+?)\*\*/g, "</ul><strong>$1</strong><ul><li>")
      .replace(/\n- (.+?)/g, "<li> $1")
      .replace(/\n/g, "<br/>");
  };

  return (
    <div
      style={{
        padding: "20px",
      }}
    >
      {Object.entries(privacyPolicyData).map(([title, content], index) => (
        <div key={index} style={{ marginBottom: "40px" }}>
          <h2 style={{ fontSize: "1.2rem" }}>■ {title}</h2>
          <span
            style={{
              whiteSpace: "pre-wrap",
              fontSize: ".8rem",
            }}
            dangerouslySetInnerHTML={{ __html: processContent(content) }}
          />
        </div>
      ))}
    </div>
  );
};

export default Privacy;
