import React, { useEffect } from "react";

const Waitlist: React.FC = () => {
  useEffect(() => {
    // Dynamically load the Tally embed script
    const script = document.createElement("script");
    script.src = "https://tally.so/widgets/embed.js";
    script.async = true;
    document.body.appendChild(script);

    // Clean up the script when the component is unmounted
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div style={{ margin: 0, height: "100%", overflow: "hidden" }}>
      <iframe
        data-tally-src="https://tally.so/r/31MoBL?transparentBackground=1"
        width="100%"
        height="100%"
        frameBorder="0"
        marginHeight={0}
        marginWidth={0}
        title="EUROPARTY - Join the waitlist"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          border: 0,
        }}
      ></iframe>
    </div>
  );
};

export default Waitlist;
