import React, { useState, useEffect } from "react";
import "./Home.css";
import Flag from "./Flag";
import { useTransition, animated } from "@react-spring/web";
import { useNavigate } from "react-router-dom";

const songs = [
  // Albania
  { country: "al", name: "Mall" }, // Albania (2018)
  { country: "al", name: "Suus" }, // Albania (2012)
  { country: "al", name: "Duje" }, // Albania (2023)

  // Andorra
  { country: "ad", name: "Salvem el món" }, // Andorra (2007)
  { country: "ad", name: "La teva decisió" }, // Andorra (2009)
  { country: "ad", name: "Casanova" }, // Andorra (2008)

  // Armenia
  { country: "am", name: "Qélé, Qélé" }, // Armenia (2008)
  { country: "am", name: "Not Alone" }, // Armenia (2014)
  { country: "am", name: "Jako" }, // Armenia (2024)
  { country: "am", name: "Snap" }, // Armenia (2022)

  // Australia
  { country: "au", name: "Sound of Silence" }, // Australia (2016)
  { country: "au", name: "Zero Gravity" }, // Australia (2019)
  { country: "au", name: "Tonight Again" }, // Australia (2015)

  // Austria
  { country: "at", name: "Rise Like a Phoenix" }, // Austria (2014) - Winning Song
  { country: "at", name: "Nobody But You" }, // Austria (2018)
  { country: "at", name: "We Will Rave" }, // Austria (2024)
  { country: "at", name: "Halo" }, // Austria (2022)

  // Azerbaijan
  { country: "az", name: "Running Scared" }, // Azerbaijan (2011) - Winning Song
  { country: "az", name: "Miracle" }, // Azerbaijan (2016)
  { country: "az", name: "Özünlə Apar" }, // Azerbaijan (2024)

  // Belgium
  { country: "be", name: "J'aime la vie" }, // Belgium (1986) - Winning Song
  { country: "be", name: "City Lights" }, // Belgium (2017)
  { country: "be", name: "Rhythm Inside" }, // Belgium (2015)

  // Bosnia & Herzegovina
  { country: "ba", name: "Lejla" }, // Bosnia & Herzegovina (2006)
  { country: "ba", name: "Love in Rewind" }, // Bosnia & Herzegovina (2011)
  { country: "ba", name: "In the Disco" }, // Bosnia & Herzegovina (2004)

  // Bulgaria
  { country: "bg", name: "Beautiful Mess" }, // Bulgaria (2017)
  { country: "bg", name: "If Love Was a Crime" }, // Bulgaria (2016)
  { country: "bg", name: "Growing Up Is Getting Old" }, // Bulgaria (2021)

  // Croatia
  { country: "hr", name: "Mama ŠČ!" }, // Croatia (2023)
  { country: "hr", name: "Marija Magdalena" }, // Croatia (1999)
  { country: "hr", name: "Rim Tim Tagi Dim" }, // Croatia (2024)

  // Cyprus
  { country: "cy", name: "Fuego" }, // Cyprus (2018)
  { country: "cy", name: "Replay" }, // Cyprus (2019)
  { country: "cy", name: "Liar" }, // Cyprus (2024)
  { country: "cy", name: "El Diablo" }, // Cyprus (2021)

  // Czech Republic
  { country: "cz", name: "Lie to Me" }, // Czech Republic (2018)
  { country: "cz", name: "Friend of a Friend" }, // Czech Republic (2019)
  { country: "cz", name: "Pedestal" }, // Czech Republic (2024)

  // Denmark
  { country: "dk", name: "Only Teardrops" }, // Denmark (2013) - Winning Song
  { country: "dk", name: "Fly on the Wings of Love" }, // Denmark (2000) - Winning Song
  { country: "dk", name: "Higher Ground" }, // Denmark (2018)

  // Estonia
  { country: "ee", name: "Everybody" }, // Estonia (2001) - Winning Song
  { country: "ee", name: "Goodbye to Yesterday" }, // Estonia (2015)
  { country: "ee", name: "Siren" }, // Estonia (2010)
  { country: "ee", name: "(Nendest) narkootikumidest ei tea me (küll) midagi" }, // Estonia (2024)

  // Finland
  { country: "fi", name: "Hard Rock Hallelujah" }, // Finland (2006) - Winning Song
  { country: "fi", name: "Cha Cha Cha" }, // Finland (2023)
  { country: "fi", name: "Dark Side" }, // Finland (2021)

  // France
  { country: "fr", name: "Voilà" }, // France (2021)
  { country: "fr", name: "Mon amour" }, // France (2024)
  { country: "fr", name: "L'Oiseau et l'Enfant" }, // France (1977)
  { country: "fr", name: "Vivre" }, // France (1983)

  // Georgia
  { country: "ge", name: "Shine" }, // Georgia (2010)
  { country: "ge", name: "One More Day" }, // Georgia (2011)
  { country: "ge", name: "Firefighter" }, // Georgia (2024)

  // Germany
  { country: "de", name: "Satellite" }, // Germany (2010) - Winning Song
  { country: "de", name: "Dschinghis Khan" }, // Germany (1979)
  { country: "de", name: "Always On The Run" }, // Germany (2024)
  { country: "de", name: "A Little Peace" }, // Germany (1982)

  // Greece
  { country: "gr", name: "My Number One" }, // Greece (2005) - Winning Song
  { country: "gr", name: "Secret Combination" }, // Greece (2008)
  { country: "gr", name: "Last Dance" }, // Greece (2021)

  // Iceland
  { country: "is", name: "Think About Things" }, // Iceland (2020)
  { country: "is", name: "Scared of Heights" }, // Iceland (2024)
  { country: "is", name: "Is It True?" }, // Iceland (2009)

  // Ireland
  { country: "ie", name: "Hold Me Now" }, // Ireland (1987) - Winning Song
  { country: "ie", name: "Why Me?" }, // Ireland (1992) - Winning Song
  { country: "ie", name: "In Your Eyes" }, // Ireland (1993) - Winning Song
  { country: "ie", name: "The Voice" }, // Ireland (1996) - Winning Song
  { country: "ie", name: "What's Another Year?" }, // Ireland (1980) - Winning Song

  // Israel
  { country: "il", name: "Toy" }, // Israel (2018) - Winning Song
  { country: "il", name: "Diva" }, // Israel (1998) - Winning Song
  { country: "il", name: "Unicorn" }, // Israel (2023)
  { country: "il", name: "Hallelujah" }, // Israel (1979)

  // Italy
  { country: "it", name: "Zitti e buoni" }, // Italy (2021) - Winning Song
  { country: "it", name: "Volare" }, // Italy (1958)
  { country: "it", name: "La noia" }, // Italy (2024)
  { country: "it", name: "Insieme: 1992" }, // Italy (1990)

  // Latvia
  { country: "lv", name: "I Wanna" }, // Latvia (2002) - Winning Song
  { country: "lv", name: "Hollow" }, // Latvia (2024)
  { country: "lv", name: "My Star" }, // Latvia (2000)

  // Lithuania
  { country: "lt", name: "On Fire" }, // Lithuania (2020)
  { country: "lt", name: "Eastern European Funk" }, // Lithuania (2010)
  { country: "lt", name: "Discoteque" }, // Lithuania (2021)

  // Luxembourg
  { country: "lu", name: "Poupée de cire, poupée de son" }, // Luxembourg (1965) - Winning Song
  { country: "lu", name: "Fighter" }, // Luxembourg (2024)
  { country: "lu", name: "L’amour Est Bleu" }, // Luxembourg (1967)
  { country: "lu", name: "Parlez-Vous Francais?" }, // Luxembourg (1978)
  { country: "lu", name: "Tu te reconnaîtras" }, // Luxembourg (1973) - Winning Song

  // Moldova
  { country: "md", name: "Hey Mamma" }, // Moldova (2017)
  { country: "md", name: "Sugar" }, // Moldova (2021)
  { country: "md", name: "Trenulețul" }, // Moldova (2022)

  // Malta
  { country: "mt", name: "Je Me Casse" }, // Malta (2021)
  { country: "mt", name: "7th Wonder" }, // Malta (2002)
  { country: "mt", name: "Loop" }, // Malta (2024)

  // Montenegro
  { country: "me", name: "Adio" }, // Montenegro (2015)
  { country: "me", name: "Moj svijet" }, // Montenegro (2014)
  { country: "me", name: "Euro Neuro" }, // Montenegro (2012)
  { country: "me", name: "Igranka" }, // Montenegro (2013)

  // Netherlands
  { country: "nl", name: "Arcade" }, // Netherlands (2019) - Winning Song
  { country: "nl", name: "Calm After the Storm" }, // Netherlands (2014)
  { country: "nl", name: "De Diepte" }, // Netherlands (2022)
  { country: "nl", name: "Ding-a-Dong" }, // Netherlands (1975)

  // Norway
  { country: "no", name: "Fairytale" }, // Norway (2009) - Winning Song
  { country: "no", name: "Spirit in the Sky" }, // Norway (2019)
  { country: "no", name: "I Feed You My Love" }, // Norway (2013)
  { country: "no", name: "Nocturne" }, // Norway (1995)
  { country: "no", name: "Let It Swing" }, // Norway (1985)

  // Poland
  { country: "pl", name: "To Nie Ja!" }, // Poland (1994)
  { country: "pl", name: "Solo" }, // Poland (2023)
  { country: "pl", name: "Color of Your Life" }, // Poland (2016)

  // Portugal
  { country: "pt", name: "Amar pelos dois" }, // Portugal (2017) - Winning Song
  { country: "pt", name: "Saudade, saudade" }, // Portugal (2022)
  { country: "pt", name: "Bem bom" }, // Portugal (1982)

  // San Marino
  { country: "sm", name: "Maybe" }, // San Marino (2014)
  { country: "sm", name: "Say Na Na Na" }, // San Marino (2019)
  { country: "sm", name: "Adrenalina" }, // San Marino (2021)

  // Serbia
  { country: "rs", name: "Molitva" }, // Serbia (2007) - Winning Song
  { country: "rs", name: "In corpore sano" }, // Serbia (2022)
  { country: "rs", name: "Ramonda" }, // Serbia (2024)

  // Slovenia
  { country: "si", name: "Sebi" }, // Slovenia (2019)
  { country: "si", name: "No One" }, // Slovenia (2011)
  { country: "si", name: "Samo ljubezen" }, // Slovenia (2002)
  { country: "si", name: "Carpe Diem" }, // Slovenia (2023)

  // Spain
  { country: "es", name: "Eres tú" }, // Spain (1973)
  { country: "es", name: "SloMo" }, // Spain (2022)
  { country: "es", name: "Bailar Pegados" }, // Spain (1991)
  { country: "es", name: "Vivo cantando" }, // Spain (1969) - Winning Song
  { country: "es", name: "La, La, La" }, // Spain (1968) - Winning Song

  // Sweden
  { country: "se", name: "Euphoria" }, // Sweden (2012) - Winning Song
  { country: "se", name: "Tattoo" }, // Sweden (2023) - Winning Song
  { country: "se", name: "Waterloo" }, // Sweden (1974)
  { country: "se", name: "Heroes" }, // Sweden (2015)
  { country: "se", name: "Hold Me Closer" }, // Sweden (2022)

  // Switzerland
  { country: "ch", name: "Ne Partez Pas Sans Moi" }, // Switzerland (1988) - Winning Song
  { country: "ch", name: "Refrain" }, // Switzerland (1956)
  { country: "ch", name: "The Code" }, // Switzerland (2024)

  // Ukraine
  { country: "ua", name: "Stefania" }, // Ukraine (2022) - Winning Song
  { country: "ua", name: "1944" }, // Ukraine (2016) - Winning Song
  { country: "ua", name: "Shum" }, // Ukraine (2021)
  { country: "ua", name: "Teresa & Maria" }, // Ukraine (2024)
  { country: "ua", name: "Dancing Lasha Tumbai" }, // Ukraine (2007)
  { country: "ua", name: "Wild Dances" }, // Ukraine (2004) - Winning Song

  // United Kingdom
  { country: "gb", name: "Love Shine a Light" }, // United Kingdom (1997) - Winning Song
  { country: "gb", name: "Space Man" }, // United Kingdom (2022)
  { country: "gb", name: "Save Your Kisses for Me" }, // United Kingdom (1976)
  { country: "gb", name: "Making Your Mind Up" }, // United Kingdom (1981)
  { country: "gb", name: "Boom Bang a Bang" }, // United Kingdom (1969)
  { country: "gb", name: "Congratulations" }, // United Kingdom (1968)
  { country: "gb", name: "Puppet On A String" }, // United Kingdom (1967)
  { country: "gb", name: "Jack In The Box" }, // United Kingdom (1971)

  // Hungary
  { country: "hu", name: "Origo" }, // Hungary (2017)
  { country: "hu", name: "Kinek mondjam el vétkeimet?" }, // Hungary (1994)
  { country: "hu", name: "Running" }, // Hungary (2014)

  // Monaco
  { country: "mc", name: "Un banc, un arbre, une rue" }, // Monaco (1971) - Winning Song
  { country: "mc", name: "Dis rien" }, // Monaco (1962)
  { country: "mc", name: "Toi, la musique et moi" }, // Monaco (1976)

  // Morocco
  { country: "ma", name: "Bitakat Hob" }, // Morocco (1980)

  // North Macedonia
  { country: "mk", name: "Proud" }, // North Macedonia (2019)
  { country: "mk", name: "Ninanajna" }, // North Macedonia (2006)
  { country: "mk", name: "Life" }, // North Macedonia (2004)

  // Romania
  { country: "ro", name: "Playing with Fire" }, // Romania (2010)
  { country: "ro", name: "Llámame" }, // Romania (2022)
  { country: "ro", name: "Yodel It!" }, // Romania (2017)

  // Slovakia
  { country: "sk", name: "Nekonečná pieseň" }, // Slovakia (1994)
  { country: "sk", name: "I'm Still Alive" }, // Slovakia (2011)
  { country: "sk", name: "Kým nás máš" }, // Slovakia (1996)

  // Türkiye (Turkey)
  { country: "tr", name: "Everyway That I Can" }, // Turkey (2003) - Winning Song
  { country: "tr", name: "Düm Tek Tek" }, // Turkey (2009)
  { country: "tr", name: "We Could Be the Same" }, // Turkey (2010)

  // Belarus
  { country: "by", name: "Da Vidna" }, // Belarus (2020)
  { country: "by", name: "Work Your Magic" }, // Belarus (2007)
  { country: "by", name: "Story of My Life" }, // Belarus (2017)

  // Russia
  { country: "ru", name: "Believe" }, // Russia (2008) - Winning Song
  { country: "ru", name: "Party for Everybody" }, // Russia (2012)
  { country: "ru", name: "Uno" }, // Russia (2020)

  // Serbia & Montenegro
  { country: "cs", name: "Lane Moje" }, // Serbia & Montenegro (2004)
  { country: "cs", name: "Zauvijek moja" }, // Serbia & Montenegro (2005)

  // Yugoslavia
  { country: "yu", name: "Rock Me" }, // Yugoslavia (1989) - Winning Song
  { country: "yu", name: "Džuli" }, // Yugoslavia (1983)
  { country: "yu", name: "Ja sam za ples" }, // Yugoslavia (1987)
];

const getRandomSong = (exclude: number[], total: number) => {
  let newIndex;
  do {
    newIndex = Math.floor(Math.random() * total);
  } while (exclude.includes(newIndex));
  return newIndex;
};

const TextRotator: React.FC<{ index: number; bottom?: boolean }> = ({
  index,
  bottom,
}) => {
  const transitions = useTransition(index, {
    from: { opacity: 0, transform: "translateY(100%)" },
    enter: { opacity: 1, transform: "translateY(0%)" },
    leave: { opacity: 0, transform: "translateY(-100%)" },
    config: { duration: 600 }, // Adjust for smoother/slower animation
  });

  return (
    <div className="song-container">
      {transitions((style, i) => (
        <animated.div style={style} className="song">
          <Flag country={songs[i].country} />
          <h1 className="song-text">
            {songs[i].name}
            {bottom ? "?" : ""}
          </h1>
        </animated.div>
      ))}
    </div>
  );
};

const icon = require(`./Icon.svg`).default;

const Home: React.FC = () => {
  const navigate = useNavigate();

  const totalSongs = songs.length;
  const [topIndex, setTopIndex] = useState(getRandomSong([], totalSongs));
  const [bottomIndex, setBottomIndex] = useState(
    getRandomSong([topIndex], totalSongs)
  );
  const [isTopTurn, setIsTopTurn] = useState(true); // Toggle between top and bottom changes

  useEffect(() => {
    const interval = setInterval(() => {
      if (isTopTurn) {
        setTopIndex((prevTopIndex) => {
          const newTopIndex = getRandomSong(
            [prevTopIndex, bottomIndex],
            totalSongs
          );
          return newTopIndex;
        });
      } else {
        setBottomIndex((prevBottomIndex) => {
          const newBottomIndex = getRandomSong(
            [topIndex, prevBottomIndex],
            totalSongs
          );
          return newBottomIndex;
        });
      }
      setIsTopTurn(!isTopTurn); // Toggle the turn between top and bottom
    }, 3000); // Change every 3 seconds

    return () => clearInterval(interval); // Clean up on unmount
  }, [isTopTurn, topIndex, bottomIndex, totalSongs]);

  const handleSubmit = (e: React.FormEvent) => {
    navigate("/waitlist"); // Navigate to the /waitlist route
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Europarty - brand new Eurovision app coming 2025",
          text: "Join the waitlist for Europarty - the new Eurovision app launching 2025",
          url: "https://europarty.app",
        });
      } catch (error) {
        console.error("Error sharing content", error);
      }
    } else {
      navigator.clipboard.writeText("https://europarty.app");
    }
  };

  return (
    <div className="landing-page">
      <header>
        <div className="logo">
          <img src={icon} alt={`Europarty icon`} width={"15rem"} />
          <div>Europarty</div>
        </div>
        <div style={{ display: "flex", gap: 10 }}>
          <button
            onClick={handleShare}
            style={{ border: "none", background: "none", cursor: "pointer" }}
          >
            <svg
              fill="none"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              viewBox="0 0 24 24"
              height="1.5em"
              width="1.5em"
            >
              <path d="M21 5 A3 3 0 0 1 18 8 A3 3 0 0 1 15 5 A3 3 0 0 1 21 5 z" />
              <path d="M9 12 A3 3 0 0 1 6 15 A3 3 0 0 1 3 12 A3 3 0 0 1 9 12 z" />
              <path d="M21 19 A3 3 0 0 1 18 22 A3 3 0 0 1 15 19 A3 3 0 0 1 21 19 z" />
              <path d="M8.59 13.51l6.83 3.98M15.41 6.51l-6.82 3.98" />
            </svg>
          </button>
          <a
            href="https://www.instagram.com/europartyapp/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1.5em"
                width="1.5em"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" />
              </svg>
            </button>
          </a>
        </div>
      </header>
      <div className="content">
        <div className="hero">
          <TextRotator index={topIndex} />
          <h2 className="or">or</h2>
          <TextRotator index={bottomIndex} bottom={true} />
        </div>
      </div>
      <footer className="hero footer text">
        <h2>Let your friends and the world know!</h2>
        <p>A new mobile experience for Eurovision launching early 2025</p>
        <form className="email-form" onSubmit={handleSubmit}>
          <button type="submit">Join the Waitlist</button>
        </form>
        <span className="disclaimer">
          Europarty is not affiliated with the Eurovision Song Contest, which is
          a trademark of the European Broadcasting Union (EBU). All trademarks
          are the property of their respective owners.
        </span>
      </footer>
    </div>
  );
};

export default Home;
